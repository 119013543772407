import { InjectionToken } from '@angular/core';

export interface IAppConfig {
  appName: string;
  version: string;
  logoUrl: string;
  apiUrl: string;
  errorRoute: string;
  adminContact: string;
  auth?: {
    loginRoute: string;
    defaultRoute: string;
    /** API URL to get an auth token (e.g. oauth/token) */
    authTokenUrl: string;
  };
  /** An object containing route keys and boolean expressions that
   *  grant permission to a route when the expression evaluates to true
   */
}

export const APP_CONFIG = new InjectionToken<IAppConfig>('APP_CONFIG');
