import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pt-tick-cross',
  templateUrl: './tick-cross.component.html',
  styleUrls: ['./tick-cross.component.scss']
})
export class TickCrossComponent implements OnInit {
  @Input() value: boolean;

  constructor() { }

  ngOnInit() {
  }

}
