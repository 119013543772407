import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, IAppConfig } from '../../models/configuration.models';
import { Observable } from 'rxjs/Observable';
import {AuthenticationRequest} from '../../models/security.models';

/** Provide this class at the top level (bootstrap or AppModule) to ensure the same instance is injected everywhere */
@Injectable()
export class AuthService {
  authStateChange$: EventEmitter<boolean> = new EventEmitter();

  get isAuthenticated(): boolean {
    return !!localStorage.getItem(this.AUTH_TOKEN_KEY);
  }

  private readonly AUTH_TOKEN_KEY: string = 'auth_token';
  private readonly USER_KEY: string = 'auth_user';
  private readonly USERNAME_KEY: string = 'auth_username';
  private readonly REMEMBER_KEY: string = 'auth_remember';

  private user: any;

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private http: HttpClient
  ) {
    if (!config)
      throw new Error('AuthService requires config to be configured as an IAppConfig implementation using the APP_CONFIG provider token');
    else if (!config.auth) {
      // tslint:disable-next-line:max-line-length
      throw new Error('AuthService requires authConfig to be configured in an IAppConfig implementation using the APP_CONFIG provider token');
    } else if (!config.auth.defaultRoute || !config.auth.loginRoute) {
      throw new Error('authConfig for APP_CONFIG provider token must have both defaultRoute and loginRoute set');
    }
  }

  currentUser<TUser>(): TUser {
    if (!this.user) {
      this.user = this.getStoredUser();
    }
    return this.user;
  }

  login(): void {
    this.saveToken('token');
    this.setUser('testing@test.com');
    const request: AuthenticationRequest = { username: 'testing@test.com', password: 'password', remember: true};
    this.setRememberedUser(request);
    this.authStateChange$.emit(this.isAuthenticated);
  }

  logout(): void {
    if (localStorage.getItem(this.REMEMBER_KEY) !== 'true') {
      localStorage.removeItem(this.USERNAME_KEY);
    }

    localStorage.removeItem(this.AUTH_TOKEN_KEY);
    localStorage.removeItem(this.USER_KEY);
    this.authStateChange$.emit(this.isAuthenticated);
  }

  getAuthHeader(): string {
    return `Bearer ${localStorage.getItem(this.AUTH_TOKEN_KEY)}`;
  }

  latestUsername(): string {
    return localStorage.getItem(this.USERNAME_KEY);
  }

  private saveToken(token: string): void {
    localStorage.setItem(this.AUTH_TOKEN_KEY, token);
  }

  private setUser(user: any): void {
    this.user = user;
    this.setStoredUser(user);
  }

  private setStoredUser(user: any): void {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  private getStoredUser(): any {
    return JSON.parse(localStorage.getItem(this.USER_KEY));
  }

  private setRememberedUser(request: AuthenticationRequest): void {
    localStorage.setItem(this.REMEMBER_KEY, `${request.remember}`);
    localStorage.setItem(this.USERNAME_KEY, request.username);
  }
}
