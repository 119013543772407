import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {APP_CONFIG, IAppConfig} from '../../_foundation/models/configuration.models';

@Injectable({
  providedIn: 'root'
})
export class NavbarHelperService {
  private settingsOptionsSharedObj = new BehaviorSubject<Array<any>>([]);
  currentSettingsOptions = this.settingsOptionsSharedObj.asObservable();

  private navDropdownVisibleSharedObj = new BehaviorSubject<boolean>(false);
  currentNavDropDownVisible = this.navDropdownVisibleSharedObj.asObservable();

  constructor(
    private router: Router,
    @Inject(APP_CONFIG) private config: IAppConfig
  ) { }

  logout() {
    this.router.navigateByUrl(this.config.auth.loginRoute);
  }

  isLoggedIn(): boolean {
    return false;
  }

  updateNavDropdownVisible(flag: boolean) {
    this.navDropdownVisibleSharedObj.next(flag);
  }
}
