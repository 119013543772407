import { Component, OnInit, Input, Inject } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '../../../models/configuration.models';
import {AuthService} from '../../../services/auth/auth.service';
import {NavbarHelperService} from '../../../../_shared/services/navbar-helper.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'pt-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  @Input('title') title: string;

  environmentLabel: string = environment.name;
  logoUrl: string;
  open: boolean = false;
  loggedIn: boolean = false;

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private authService: AuthService,
    private navBarHelperService: NavbarHelperService
  ) {
    this.title = config.appName;
    this.logoUrl = config.logoUrl;
  }

  ngOnInit() {
    this.loggedIn = this.authService.isAuthenticated;
    this.authService.authStateChange$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });

    this.navBarHelperService.currentNavDropDownVisible.subscribe(visible => {
      this.open = visible;
    });
  }
}
