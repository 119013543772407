import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { APP_CONFIG, IAppConfig } from '../../../models/configuration.models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version: string;

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig
  ) {
    this.version = config.version;
  }

  ngOnInit() {
  }

}
