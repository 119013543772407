import { Component, OnInit, Inject } from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { APP_CONFIG, IAppConfig } from '../../../_foundation/models/configuration.models';
import { AuthService } from '../../../_foundation/services/auth/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  authenticated: boolean;

  constructor(
    private router: Router,
    @Inject(APP_CONFIG) private config: IAppConfig,
    private authService: AuthService,
  ) {
    this.authenticated = authService.isAuthenticated;
  }

  ngOnInit() {
    this.authService.authStateChange$.subscribe(authenticated => {
      this.authenticated = authenticated;
    });
  }

}
