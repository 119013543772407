import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '../../_foundation/models/configuration.models';
import { HttpClient } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';

@Injectable()
export class GridDataService {

  constructor(
    @Inject(APP_CONFIG) private config: IAppConfig,
    private http: HttpClient
  ) {}

  resetFilters (state: State, firstField: string, filters?: any) {
    state.sort = [];
    filters = null;
    state.filter = null;

    state.take = 20,
    state.sort = [{
      field: firstField,
      dir: 'asc'
    }];
  }

  public setStoredState(state: any, name: string): void {
    localStorage.setItem(name, JSON.stringify(state));
  }

  public getStoredState(name: string): any {
    return JSON.parse(localStorage.getItem(name));
  }

  public removeStoredState(name: string): any {
    localStorage.removeItem(name);
  }
}
