import { IAppConfig } from './_foundation/models/configuration.models';

export class AppConfig implements IAppConfig {
  appName = 'PurpleTuesday Test Demo';
  version = '1.0.0';
  logoUrl = 'assets/pt-opengraph.png';
  apiUrl = 'test';
  errorRoute = '/error';
  adminContact = 'test';
  auth = {
    loginRoute: '/auth/login',
    defaultRoute: '/customers',
    authTokenUrl: 'oauth/token'
  };
}
