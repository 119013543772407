import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import { AppComponent } from './app.component';
import { CustomerGridComponent } from './customers/components/customer-grid/customer-grid.component';
import { CustomerEditorComponent } from './customers/components/customer-editor/customer-editor.component';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './_shared/modules/shared.module';
import {FoundationModule} from './_foundation/foundation.module';
import {ToastrModule} from 'ngx-toastr';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_CONFIG} from './_foundation/models/configuration.models';
import {AppConfig} from './app.config';
import {AuthService} from './_foundation/services/auth/auth.service';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FoundationModule,
    ToastrModule.forRoot(),
    LoadingBarHttpClientModule,
    HttpClientModule,
    DropDownsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: APP_CONFIG, useClass: AppConfig },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
