import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../../../_foundation/services/auth/auth.service';
import { Router } from '@angular/router';
import { APP_CONFIG, IAppConfig } from '../../../_foundation/models/configuration.models';
import { RTL } from '@progress/kendo-angular-l10n';
import {NavbarHelperService} from '../../services/navbar-helper.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  authenticated: boolean;
  public popupClass: string = 'pt-animation-container d-none d-md-none d-lg-block';
  iconClass: string = 'fa fa-cog fa-fw';

  public data: Array<any> = [];

  constructor(
    private router: Router,
    @Inject(APP_CONFIG) private config: IAppConfig,
    private authService: AuthService,
    private navbarHelperService: NavbarHelperService
  ) {
    this.authenticated = authService.isAuthenticated;
  }

  ngOnInit() {
    this.data = [{
      text: 'Change Password',
      click: (dataItem) => {

      }
    },
      {
        text: 'Log Out',
        click: (dataItem) => {
          this.authService.logout();
          this.router.navigateByUrl('/auth/login');
        }
      }];

    this.authService.authStateChange$.subscribe(authenticated => {
      this.authenticated = authenticated;
      });
    }
}
