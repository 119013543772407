import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from '../components/navbar/navbar.component';
import {SidebarComponent} from '../components/sidebar/sidebar.component';
import {FoundationModule} from '../../_foundation/foundation.module';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import {ButtonsModule} from '@progress/kendo-angular-buttons';
import {GridDataService} from '../services/grid-data.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FoundationModule,
    DropDownsModule,
    ButtonsModule
  ],
  providers: [
    GridDataService
  ],
  declarations: [
    NavbarComponent,
    SidebarComponent
  ],
  exports: [
    NavbarComponent,
    SidebarComponent
  ]
})
export class SharedModule { }
