import { NgModule, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NavComponent } from './components/layout/nav/nav.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import {CollapseModule} from 'ngx-bootstrap';
import {TickCrossComponent} from './components/tick-cross/tick-cross.component';
import {ValidationSummaryComponent} from './components/validation-summary/validation-summary.component';


@NgModule({
  imports: [
    CommonModule,
    CollapseModule
  ],
  declarations: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    TickCrossComponent,
    ValidationSummaryComponent
  ],
  providers: [

  ],
  exports: [
    NavComponent,
    SidebarComponent,
    FooterComponent,
    TickCrossComponent,
    ValidationSummaryComponent
  ]
})
export class FoundationModule { }
